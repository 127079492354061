import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Pdf from '../../files/resume.pdf'

const NavigationBar = () => {
    return (
        <Navbar expand="lg" className="bg-body-tertiary navbar">
            <Container>
                <Navbar.Brand href="/"><i className="fa-solid fa-code"></i> Michael Nichol</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto navbar-nav flex-space-between d-flex w-100">
                        <div className={'d-flex'} id={'navbar-left'}>
                            <Nav.Link className={'nav-item'} href="/about">About Me</Nav.Link>
                            <Nav.Link className={'nav-item'} href="/projects">Projects</Nav.Link>
                            <Nav.Link className={'nav-item'} href="/contact">Contact</Nav.Link>
                        </div>
                        <div className={'d-flex'} id={'navbar-right'}>
                            <Nav.Link className={'nav-item'} href={Pdf} target = "_blank">Resume</Nav.Link>
                            <Nav.Link className={''} href="https://www.linkedin.com/in/michael-nichol-919793166/" target = "_blank"><i className="fa-brands fa-linkedin fa-xl linkedin-text"></i></Nav.Link>
                            <Nav.Link className={''} href="https://github.com/mtdnichol" target = "_blank"><i className="fa-brands fa-github fa-xl github-text"></i></Nav.Link>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavigationBar;