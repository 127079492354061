import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './components/css/Main.css';

// Routes
import Landing from "./components/pages/Landing";
import NavigationBar from "./components/utilities/Navbar";
import About from './components/pages/About'
import Projects from './components/pages/Projects'
import Contact from './components/pages/Contact'

function App() {
  return (
      <Router>
          <NavigationBar/>
          <Routes>
              <Route path={"/"} element={<Landing/>} />
              <Route path={"/about"} element={<About/>} />
              <Route path={"/projects"} element={<Projects/>} />
              <Route path={"/contact"} element={<Contact/>} />
          </Routes>
      </Router>
  );
}

export default App;
