import React from "react";
import Container from "react-bootstrap/Container";

const Contact = () => {
    return (
        <section className={""}>
            <Container>

            </Container>
        </section>
    )
}

export default Contact;