import React from "react";
import Container from "react-bootstrap/Container";
import photo from '../../images/20210820_112419.jpg'

const Landing = () => {
    return (
        <section className={""}>
            <Container>
                <div id={'section-header'} className={'landing-header'}>
                    <div>
                        <img src={photo} alt={'missing'}/>
                    </div>
                    <div className={'landing-content'}>
                        <h1 className={'text-align-center'}>Michael Nichol</h1>
                        <h3 className={'text-align-center'}>Software Developer</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras venenatis aliquet magna quis consectetur. Nam vel enim ut arcu vulputate tristique commodo ac neque. In fermentum urna nunc, vitae aliquam eros congue in. Praesent suscipit urna ut porta condimentum. Aenean aliquam nequ
                        </p>
                        <div className={'flex-center'}>
                            <h4 className={'text-align-center'}>Skills</h4>
                            <table className={'skills'}>
                                <tbody>
                                    <tr>
                                        <td className={'bold text-align-right'}>Programming</td>
                                        <td>C#, Java, Python, Lua</td>
                                    </tr>
                                    <tr>
                                        <td className={'bold text-align-right'}>Web</td>
                                        <td>HTML, CSS, JS</td>
                                    </tr>
                                    <tr>
                                        <td className={'bold text-align-right'}>Database</td>
                                        <td>MongoDB</td>
                                    </tr>
                                    <tr>
                                        <td className={'bold text-align-right'}>Tools</td>
                                        <td>Git, Jira, Subversion</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div id={'section-projects'}>

                </div>
            </Container>
        </section>
    )
}

export default Landing;