import React from "react";
import Container from "react-bootstrap/Container";

const Projects = () => {
    return (
        <section className={""}>
            <Container>
                <h2>My Projects</h2>
                <div className={'project'}>
                    <h4>Test Project A</h4>
                    <p>Talking about the project</p>
                    <p>Coding Stack - </p>
                    <div className={'d-flex flex-center'}>
                        <a className={'btn btn-outline-primary'} href="#" role="button">View Website</a>
                        <a className={'btn btn-outline-primary'} href="#" role="button">View Source Code</a>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Projects;